import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

let mergeClassNames=function(a,b) {
    if (!a) return b;
    if (!b) return a;
    return a + " " + b;
  }
  
export class FullRow extends React.Component {
    render() {
        return <Row {...this.props} className={mergeClassNames("fullrow",this.props.className)}>{this.props.children}</Row>
    }
}

export class ShortRow extends React.Component {
    render() {
        return <div {...this.props}  className={mergeClassNames("d-flex",this.props.className)}>{this.props.children}</div>
    }
}

export class FullCol extends React.Component {
    render() {
        return <Col {...this.props} className={mergeClassNames("fullcol",this.props.className)}>{this.props.children}</Col>
    }
}