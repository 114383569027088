import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

export default class DynamicList extends React.Component {
    state = {
        itemsShownFrom: 0,
        itemsShownTo: 0
    }

    componentDidMount() {
        this.resize();
    }

    resize(scrollTop, height) {
        height = height || 1000;
        scrollTop = scrollTop || 0;

        let scrollBottom = scrollTop + height;

        let visibleFrom = Math.floor(scrollTop / this.itemSize());
        let visibleTo = Math.ceil(scrollBottom / this.itemSize());

        if (visibleFrom >= this.state.itemsShownFrom && visibleTo <= this.state.itemsShownTo) return;

        let minIndex = 0;
        let maxIndex = this.props.list?this.props.list.length:0;

        let targetFrom = Math.max(visibleFrom - (visibleTo - visibleFrom),minIndex);
        let targetTo   = Math.min(visibleTo   + (visibleTo - visibleFrom),maxIndex);

        this.setState({
            itemsShownFrom: targetFrom,
            itemsShownTo: targetTo
        });
    }

    onScroll(event) {
        let element = event.target;
        this.resize(element.scrollTop, element.offsetHeight);
    }

    itemSize() { return this.props.itemSizePx || (this.props.itemSizeEm * 16);}

    render() {
        if (!this.props.list) return null;

        let firstItemSize = this.state.itemsShownFrom * this.itemSize();
        let lastItemSize = (this.props.list.length - this.state.itemsShownTo) * this.itemSize();

        if (lastItemSize < 0 )lastItemSize = 0;

        return (
            <ListGroup className="dynamic-list" onScroll={(event) => this.onScroll(event)}>
                {!!firstItemSize && <ListGroup.Item style={{minHeight:firstItemSize+"px",height:firstItemSize+"px"}}/>}

                {
                this.props.list.map((item,itemIndex) => {
                    if (itemIndex < this.state.itemsShownFrom || itemIndex >= this.state.itemsShownTo) return null;
                    return (
                        <ListGroup.Item
                            key={itemIndex} onClick={() => this.props.onItemClick(item)}
                            style={{minHeight:this.itemSize()+"px",height:this.itemSize()+"px"}}>
                            {this.props.children(item)}
                        </ListGroup.Item>
                    );
                }).filter(x => x)
                }

                {!!lastItemSize && <ListGroup.Item style={{minHeight:lastItemSize+"px",height:lastItemSize+"px"}}/>}
            </ListGroup>
        );
    }
}