import React, { createRef } from "react";

import TranslationManager from "../Managers/Translation";

/**
 * <LursoftMap... />
 * 
 * Supported React props:
 * @param {json} map (required) a card.map field from the noSQL database (as is) 
 * @param {string} url (required) Lursoft URL with {x} and {y} for the center and {z} for zoom (1-17)
 */
export default class LursoftMap extends React.Component {
  static contextType = TranslationManager.Context;
  iframeRef;

  constructor(props) {
    super(props);
    this.iframeRef = createRef();
  }

  postMessage() {
    if (this.iframeRef.current) {
        // the extension is available
        console.log("POSTING ", this.props.map.pins);
        this.iframeRef.current.contentWindow.postMessage({ter_coos:this.props.map.pins}, "*");
        return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.postMessage())
      setTimeout(()=>this.postMessage(),1000);
  }

  componentDidUpdate(prevProps, prevState) {
    this.postMessage();
  }

  render() {
    console.log("LursoftMap",this.props.url);
    let retVal = <div>{this.context.getTranslation('$no_browser_extension')}</div>;
    if (window.ter_lursoft_extension_installed) {
      retVal = (
        <iframe
            ref={this.iframeRef}
          src={this.props.url
            .split("{x}")
            .join(this.props.map.simpleLon)
            .split("{y}")
            .join(this.props.map.simpleLat)
            .split("{z}")
            .join(this.props.map.simpleZoom)}
          width="100%"
          height="100%"          
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      );
    }

    return retVal;
  }
}
