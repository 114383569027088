import React from 'react';
import TranslationManager from '../Managers/Translation';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

export default class LanguageSelector extends React.Component {
    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <DropdownButton title={context.getTranslation(context.language)}>
                {
                    context.getLanguagesList().map((item, itemIndex) => (
                        <Dropdown.Item key={itemIndex} href="#" onClick={(event) => {event.preventDefault(); context.setLanguage(item)}}>
                            {context.getTranslation(item)}
                        </Dropdown.Item>
                    ))
                }
                </DropdownButton>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}