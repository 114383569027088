import React from 'react';
import TranslationManager from '../Managers/Translation';
import {Remove as RemoveIcon} from './Icons';
import Button from 'react-bootstrap/Button';

export default class CardHistoryList extends React.Component {
    formatDate(date,format) {
        if (!date) return "";
        return new Date(date).format(format);
    }

    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <table style={{width:"100%"}}>
                    <thead>
                        <tr style={{textAlign:"center", background:"#ccc"}}>
                            <th>{context.getTranslation("$history_who")}</th>
                            <th>{context.getTranslation("$history_group")}</th>
                            <th>{context.getTranslation("$history_from")}</th>
                            <th>{context.getTranslation("$history_to")}</th>
                            {!this.props.readOnly && <th></th>}
                        </tr>
                    </thead>
                    {!!this.props.card.history &&
                        <tbody>
                            {this.props.card.history.map((item, index) => {
                                    if (index >= 3) return null;

                                    return (
                                        <tr key={index} style={{textAlign:"center"}}>
                                            <td style={{textAlign:"left"}}>{item.who}</td>
                                            <td>{item.group}</td>
                                            <td>{this.formatDate(item.fromDate,"dd.MM.yyyy")}</td>
                                            <td>{this.formatDate(item.toDate,"dd.MM.yyyy")}</td>
                                            {!this.props.readOnly &&
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        onClick={(event) => this.props.onChange(this.props.card.removeHistory(index))}
                                                    >
                                                        <RemoveIcon/>
                                                    </Button>
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    }
                </table>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}