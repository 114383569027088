import React from 'react';
import Form from 'react-bootstrap/Form';

export default class RefusalsField extends React.Component {
    state = {isValid: true}

    getRefusalsSimpleString() {
        return Object.keys(this.props.value).join(", ");
    }

    getRefusalsFullString() {
        return Object.keys(this.props.value)
                     .map(key => key + ": " + (this.props.value[key] || "???"))
                     .join(", ");
    }

    onTextChange(newText) {
        
        if (!newText) newText = "";
        
        newText = newText.trim();

        let newObj = {};

        if (newText) {
            var entries = newText.split(",");
            entries = entries.map(str => str.split(":").map(x => x.trim()));

            
            
            for (let i = 0; i < entries.length; ++i) {

                if (entries[i].length != 2) {
                    this.setState({isValid: false});
                    return;
                }

                if (!entries[i][0] || !entries[i][1]) {
                    this.setState({isValid: false});
                    return;
                }

                newObj[entries[i][0]] = entries[i][1];
            }
        }

        

        this.setState({isValid: true});
        if (this.props.onChange) this.props.onChange(newObj);
    }

    render () {
        let {value, ...otherProps} = this.props;

        if (this.props.readOnly) return (<Form.Control key={0} {...otherProps} value={this.getRefusalsSimpleString()} />);


        let validity = {};
        if (this.state.isValid)
            validity.isValid = true;
        else
            validity.isInvalid = true;

        return (
            <Form.Control
                {...otherProps}
                key={this.props.value}
                defaultValue={this.getRefusalsFullString()}
                onChange={(event) => this.onTextChange(event.target.value)}
                {...validity}/>
        );
    }
}