import React from 'react';
import TranslationManager from '../Managers/Translation';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {Remove as RemoveIcon, Plus as PlusIcon} from './Icons';

export default class CardTagsList extends React.Component {
    state = {}

    getBadgeVariant(tag) {
        if (tag == "$tag_available") return "success";
        if (tag == "$tag_onhands") return "danger";
        return "secondary";
    }

    canDeleteTag(tag) {
        if (tag == "$tag_available" || tag == "$tag_onhands") return false;

        return true;
    }

    onTagClick(tagIndex, tag, tagName) {
        console.log("onTagClick(tagIndex:",tagIndex,",tag:",tag,",tagName:",tagName);
        if (this.props.readOnly && this.props.onClick)
            this.props.onClick(tagIndex, tag, tagName);
        if (!this.props.readOnly && this.props.onRemoveClick && this.canDeleteTag(tag))
            this.props.onRemoveClick(tagIndex, tag, tagName);
    }

    onNewTag(tag) {
        if (this.props.onNewClick)
            this.props.onNewClick(tag);
        this.setState({newTag:""});
    }

    render() {
        let {tags,readOnly, onClick, onRemoveClick, onNewClick,...otherProps} = this.props;
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <div className="d-flex" {...otherProps}>

                    {tags && tags.map((tag, tagIndex)=>(
                        <Button
                            key={tagIndex}
                            variant={this.getBadgeVariant(tag)}
                            size="sm"
                            style={{margin:"0.125em"}}
                            onClick={(event) => {
                                event.stopPropagation();
                                this.onTagClick(tagIndex, tag, context.getTranslation(tag));
                            }}
                        >
                            {context.getTranslation(tag)}
                            {!this.props.readOnly && this.canDeleteTag(tag) &&
                                <Badge style={{fontSize:"0.75em", marginLeft:"0.5em"}}><RemoveIcon/></Badge>
                            }
                        </Button>
                    ))}
                    {!this.props.readOnly &&
                        <React.Fragment>
                            <Form.Control
                                type="text"
                                placeholder="New tag..."
                                style={{padding: ".0rem .25rem", width: "6em"}}
                                value={this.state.newTag || ""}
                                onChange={(event) => this.setState({newTag: event.target.value})}
                            />
                        
                            <Button
                                variant="info"
                                size="sm"
                                style={{margin:"0.125em"}}
                                onClick={(event) => {event.stopPropagation(); this.onNewTag(this.state.newTag);}}
                            >   
                                <PlusIcon/>
                            </Button>
                        </React.Fragment>
                    }
                </div>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}