import React from 'react';
import TranslationManager from '../Managers/Translation';
import AuthenticationManager from '../Managers/Authentication';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatePicker from "../Components/DatePicker";

export default class GiveoutDialog extends React.Component {
    static contextType = AuthenticationManager.Context;

    state = {
         person: ""
        ,group: null
        ,date: new Date()
    }

    render() {
        //console.log("GIVEOUT_DIALOG: getGroupData() = ",this.context.getUserData());
        if (!this.state.group) this.state.group = this.context.getUserData().group; //HACK: never change state uncontrolled, but it's a fast workaround

        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <Modal show={this.props.show} centered>
                    <Modal.Header><Modal.Title>{context.getTranslation("$giveout_title")}</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(event) => {event.preventDefault(); this.props.onGiveout(this.state)}}>

                            <Form.Group>
                                <Form.Label>{context.getTranslation("$giveout_person")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={context.getTranslation("$giveout_person") + "..."}
                                    value={this.state.person}
                                    onChange={(event) => this.setState({person:event.target.value})}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{context.getTranslation("$giveout_group")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={context.getTranslation("$giveout_group") + "..."}
                                    value={this.state.group}
                                    onChange={(event) => this.setState({group:event.target.value})}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{context.getTranslation("$giveout_date")}</Form.Label>
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.date}
                                        onChange={newDate => this.setState({date:newDate})}
                                        dateFormat="dd.MM.yyyy"
                                    />
                                </div>
                            </Form.Group>

                            <Button
                                variant="primary" type="submit"
                                disabled={!(this.state.person && this.state.group && this.state.date)}
                            >
                                {context.getTranslation("$giveout_submit")}
                            </Button>

                            <Button
                                variant="secondary"
                                onClick={this.props.onCancel}
                            >
                                {context.getTranslation("$giveout_cancel")}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}