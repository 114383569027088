import React from 'react';
import $ from "jquery";

import TranslationManager from '../Managers/Translation';

export default class PinsInput extends React.Component {

    static contextType = TranslationManager.Context;

    constructor(props) {
        super(props);
        this.state = {
            pinsAsString: props.pins?JSON.stringify(props.pins):""
        };

    }

    callOnChange(newVal) {
        // newVal will a JS object
        if (this.props.onChange) {
            this.props.onChange( JSON.parse(JSON.stringify(newVal)) );
        }
    }


    render() {
        return (<div style={{position: "relative", top:"-50px", zIndex:1000, height:0}}>
                    <span style={{backgroundColor:"white",opacity:0.8,whiteSpace: "nowrap",fontSize:"small"}}><b>{this.context.getTranslation("$enter_pins")}</b></span><br/>
                    <input style={{width:"100%"}} type="text" value={this.state.pinsAsString}
                        onChange={(event)=>{this.setState({pinsAsString:$(event.target).val()})}}
                        onBlur={(event)=>{
                            let txt = $(event.target).val();
                            if (!txt || (txt.length==0)) { // nothing entered
                            this.callOnChange([]);
                            }
                            else
                            if (txt[0]=='[') { // array entered
                            try {
                                let newVal = JSON.parse(txt);
                                this.callOnChange(newVal);
                            }
                            catch(t) {                            
                                alert("Parse error "+t);
                            }
                            }
                            else { // address entered
                                console.log(this.props.geocode);
                                if (this.props.geocode) {
                                    let newPins = this.props.geocode(txt);
                                    if (newPins) {
                                        this.setState({pinsAsString:JSON.stringify(newPins)});
                                        this.callOnChange(newPins);
                                    }
                                }  
                            }
                        }}
                        onKeyDown={(event)=>{if (event.key=="Enter") {let target = $(event.target); target.blur(); setTimeout( function(){target.focus();},0); }} }                        
                    >                        
                    </input>
                </div>);        
    }

}