import React from 'react';
import TranslationManager from '../Managers/Translation';

import Spinner from 'react-bootstrap/Spinner';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {FullRow, ShortRow as Row} from '../Components/BootstrapExtensions';
import {
    Share as ShareIcon,
    Map as MapIcon,
    Pencil as PencilIcon,
    Remove as RemoveIcon,
    Unlocked as UnlockedIcon,
    Locked as LockedIcon} from '../Components/Icons';
import CardTagsList from '../Components/CardTagsList';
import CardTemplate from '../Components/CardTemplate';
import CardHistoryList from '../Components/CardHistoryList';
    


export default class CardView extends React.Component {
    render() {
        if (this.props.card === undefined) return null;
        if (!this.props.card) return <Spinner animation="border" variant="secondary" style={{marginTop:"8px",marginLeft:"calc(50% - 16px)"}}/>

        if (this.props.endUser) {
            return (
<CardTemplate
                            card={this.props.card}
                            readOnly={this.props.readOnly}
                            onChange={this.props.onChange}
                        />
            );
        }
        
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <FullRow id="cardview">
                    <Col xs={12} className="cardspage-viewheader">

                        {this.props.readOnly &&
                            <Row>
                                <Col xs="auto">
                                    { this.props.card.isAvailable() &&
                                        <Button onClick={() => this.props.onGiveout()}>{context.getTranslation("$giveout")}</Button>
                                    }
                                    {!this.props.card.isOnHands() &&
                                        <Button onClick={() => this.props.onSplit()}>{context.getTranslation("$split")}</Button>
                                    }
                                    {this.props.card.isOnHands() &&
                                        <Button onClick={() => this.props.onReturn()} >{context.getTranslation("$return")}</Button>
                                    }
                                    {this.props.card.isOnHands() &&
                                        <Button onClick={() => this.props.onShareLink(this.props.card)}><ShareIcon/></Button>
                                    }
                                    
                                </Col>
                                <Col xs="auto" className="col-md-0">&nbsp;
                                    <Button onClick={() => this.props.onMapSelect(this.props.card.number)}><MapIcon/></Button>
                                </Col>
                                <Col/>
                                <Col xs="auto">
                                    <Button onClick={() => this.props.onEditStart()}><PencilIcon/></Button>
                                    <Button disabled><UnlockedIcon/></Button>
                                </Col>
                            </Row>
                        }

                        {!this.props.readOnly &&
                            <Row className="no-gutters">
                                <Col xs="auto">
                                    <Button variant="success" disabled={this.props.blockInEditMode} onClick={() => this.props.onEditSave()}>{context.getTranslation("$save")}</Button>
                                    <Button variant="danger" disabled={this.props.blockInEditMode} onClick={() => this.props.onEditCancel()}>{context.getTranslation("$dont_save")}</Button>
                                </Col>
                                <Col/>
                                <Col xs="auto">
                                    <Button variant="danger" disabled={this.props.blockInEditMode} onClick={() => this.props.onDelete()}>{context.getTranslation("$delete")}</Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col xs={12} className="cardspage-viewcontent d-flex flex-column" style={{height:"calc(100% - 13rem)", minHeight:"20em"}}>
                        <CardTagsList
                            tags={this.props.card.tags}
                            readOnly={this.props.readOnly}
                            onRemoveClick={(tagIndex) => this.props.onChange(this.props.card.removeTag(tagIndex))}
                            onNewClick={(tag) => this.props.onChange(this.props.card.addTag(tag))}
                        />
                        <CardTemplate
                            card={this.props.card}
                            readOnly={this.props.readOnly}
                            onChange={this.props.onChange}
                        />
                    </Col>
                    <Col xs={12} className="cardspage-viewcontent" style={{height:"10rem"}}>
                        <CardHistoryList
                            card={this.props.card}
                            readOnly={this.props.readOnly}
                            onChange={this.props.onChange}
                        />
                    </Col>
                </FullRow>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}

