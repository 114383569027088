import React from 'react';
import TranslationManager from '../Managers/Translation';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';


export default class CardListSearch extends React.Component {
    state = {
        sortColumns: ["name", "number", "date"]
    }

    updateFilterString(newFilterString) {
        if (!this.props.onFilterStringChange) return;
        this.props.onFilterStringChange(newFilterString);
    }

    updateSortColumn(newSortColumn) {
        if (!this.props.onSortColumnChange) return;
        this.props.onSortColumnChange(newSortColumn);
    }

    toggleSortDirection() {
        if (!this.props.onSortDirectionChange) return;
        this.props.onSortDirectionChange(this.getToggledSortDirection());
    }

    getToggledSortDirection() {
        switch (this.props.sortDirection) {
            case  "asc": return "desc";
            case "desc": return  "asc";
            default: return "asc";
        }
    }

    getSortDirectionLabel() {
        switch (this.props.sortDirection) {
            case  "asc": return <React.Fragment>&#8595;</React.Fragment>;
            case "desc": return <React.Fragment>&#8593;</React.Fragment>;
            default: return <React.Fragment>-</React.Fragment>;
        }
    }

    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (langContext) => (
                <Form style={{width:"100%"}} onSubmit={(event) => event.preventDefault()}>
                    <InputGroup>
                        <FormControl
                            type="text"
                            placeholder={langContext.getTranslation("$search") + "..."}
                            value={this.props.filterString}
                            onChange={(event) => this.updateFilterString(event.target.value)}
                            />

                        <Dropdown as={InputGroup.Append}>
                            <Button
                                variant="outline-secondary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.toggleSortDirection();
                                }}>
                                {this.getSortDirectionLabel()}
                            </Button>

                            <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />

                            <Dropdown.Menu>
                                {this.state.sortColumns.map((item,itemIndex) => (
                                    <Dropdown.Item
                                        key={itemIndex}
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.updateSortColumn(item);
                                        }}>
                                        {langContext.getTranslation("$sort_"+item)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </InputGroup>
                </Form>
            )}
            </TranslationManager.Context.Consumer>
        );
        
    }
}