import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import React from 'react';

import TranslationManager from './Managers/Translation';
import AuthenticationManager from './Managers/Authentication';
import RoutingManager, {Link, Route}  from './Managers/Routing';
import DataManager from './Managers/Data';

import Toolbar from './Components/Toolbar';

import CardsPage from './Pages/Cards';
import CardsReports from './Pages/Reports';
import BigMap from './Pages/BigMap';


export default class Application extends React.Component {
    render() {
        /*override component names for better code readability*/
        return (
            <TranslationManager>
                <AuthenticationManager>
                    <RoutingManager>
                        <DataManager>

                            <RoutingManager.Context.Consumer>
                            {(routing) => (
                                <TranslationManager.Context.Consumer>
                                {(context) => (
                                        <React.Fragment>
                                            
                                            <Toolbar>
                                                <Link to="/app/cards"  >{context.getTranslation("$page_cards")}</Link>
                                                <Link to="/app/reports">{context.getTranslation("$page_reports")}</Link>
                                                <Link to="/app/bigmap">{context.getTranslation("$page_bigmap")}</Link>
                                            </Toolbar>

                                            
                                            <Route path="/app/cards"  ><CardsPage   routing={routing}/></Route>
                                            <Route path="/app/reports"><CardsReports routing={routing}/></Route>
                                            <Route path="/app/bigmap"><BigMap routing={routing}/></Route>
                                            
                                        </React.Fragment>
                                )}
                                </TranslationManager.Context.Consumer>
                            )}
                            </RoutingManager.Context.Consumer>

                        </DataManager>
                    </RoutingManager>
                </AuthenticationManager>
            </TranslationManager>
        );
    }
}
