import React from "react";
import TranslationManager from "../Managers/Translation";
import TilesMap from "./TilesMap";
import LursoftMap from "./LursoftMap";
import $ from "jquery";

export function getMapProvidersForCard(card) {
  let retVal = ["OpenStreetMap"];
  if (card && card.tags && card.tags.includes("$tag_LursoftMap")) retVal.push("LursoftMap");

  return retVal;
}

export function getTilesUrlForMapProvider(mapProvider) {
  return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  // currently, return openstreetmap URL; in the future, we can return Lursoft or BalticMaps URLs as well
}

function geocode(address) {
  let retVal = null;
  $.ajax({
    async: false,
    dataType: "json",
    url:
      "https://ter.webappos.org/nominatim/search/" +
      address.split(" ").join("%20") +
      "?format=json&addressdetails=1&limit=1",
    success: function (data) {
      if (data.length == 0) {
        alert(
          "No data returned. Try to change the word order (put the city/town in the end)."
        );
      } else
        retVal = [
          [{ lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon) }],
        ];
    },
    error: function (jqXHR, exception) {
      var msg = "";
      if (jqXHR.status === 0) {
        msg = "Not connect.\n Verify Network.";
      } else if (jqXHR.status == 404) {
        msg = "Requested page not found. [404]";
      } else if (jqXHR.status == 500) {
        msg = "Internal Server Error [500].";
      } else if (exception === "parsererror") {
        msg = "Requested JSON parse failed.";
      } else if (exception === "timeout") {
        msg = "Time out error.";
      } else if (exception === "abort") {
        msg = "Ajax request aborted.";
      } else {
        msg = "Uncaught Error.\n" + jqXHR.responseText;
      }
      alert("Geocoding error - " + msg);
    },
  });

  return retVal;
}

/**
 * <MapComponent.../>
 * A wrapper over TilesMap, LursoftMap...
 *
 * Supported React props:
 * @param map (required) a card.map field from the noSQL database (as is)
 * @param mapProvider (required) an ID of a supported map provider, e.g., "OpenStreetMap" or "LursoftMap"
 * @param {boolean} [editable=false] whether the given map should be editable (i.e., the palette and the input field for the coos array is displayed)
 * @param {function} [onChange] the callback function to be called when something has been edited on a map; the function receives only the card.map field, e.g., onChange({map: newMap})
 * @param {function} [onEditStart] the void callback function to be called when some editing has been started (e.g., a polygon is being drawn); the parent function can use it to disable certain UI elements during drawing
 * @param {function} [onEditStop] the void callback function to be called when editing has been finished or cancelled (in the former case, onChange will also be called)
 */
export default class MapComponent extends React.Component {
  static contextType = TranslationManager.Context;

  constructor(props) {
    super(props);
  }

  getDefaultPosition() {
    return [56.944, 24.243, 17];
  }

  getPosition(map) {
    if (!map) return this.getDefaultPosition();

    //use explicit parameters if they exist
    if (map.simpleLat && map.simpleLon)
      return [map.simpleLat, map.simpleLon, map.simpleZoom || 17];

    //for single pin, use pin's coordinates with default zoom (17)
    if (map.pins && map.pins.length == 1 && map.pins[0].length == 1) {
      return [map.pins[0][0].lat, map.pins[0][0].lon, map.simpleZoom || 17];
    }

    //for multiple pins/polygons calculate:
    //  position as a center of a bounding rectangle
    //  zoom as a maximal zoom when bounding rectangle fits into a single tile (both for lat/lon) with adjustment
    if (
      map.pins &&
      map.pins.length &&
      (map.pins.length > 1 || map.pins[0].length > 1)
    ) {
      let minLat = Math.min(...map.pins.flat().map((x) => x.lat));
      let maxLat = Math.max(...map.pins.flat().map((x) => x.lat));
      let minLon = Math.min(...map.pins.flat().map((x) => x.lon));
      let maxLon = Math.max(...map.pins.flat().map((x) => x.lon));

      let lat = (minLat + maxLat) / 2;
      let lon = (minLon + maxLon) / 2;
      let zoom = Math.min(
        17, //limit zoom to 17, higher than 17 is too close
        Math.trunc(
          Math.min(
            Math.log2((360 * 0.4) / (maxLat - minLat)), //0.4 - approximation for 50-60 latitude
            Math.log2(360 / (maxLon - minLon))
          ) + 1.0 //0.8 - imperical adjustment to zoom
        )
      );
      return [lat, lon, zoom];
    }

    return this.getDefaultPosition();
  }

  render() {
    // fix the map json...
    let map = this.props.map || {};
    map.pins = map.pins || [];

    let position = this.getPosition(map);

    map.simpleLat = map.simpleLat || position[0];
    map.simpleLon = map.simpleLon || position[1];
    map.simpleZoom = map.simpleZoom || position[2];

    if (this.props.mapProvider === "OpenStreetMap") {
      return (
        <TilesMap
          map={map}
          tilesUrl="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          geocode={geocode}
          editable={this.props.editable}
          onChange={this.props.onChange}
          onEditStart={this.props.onEditStart}
          onEditStop={this.props.onEditStop}
        ></TilesMap>
      );
    }

    if (this.props.mapProvider === "LursoftMap") {
        return (
          <LursoftMap
            map={map}
            url="https://maps.lursoft.lv/lv/company/index?zoom=&#123;z&#125;&center=&#123;y&#125;;&#123;x&#125;"
          ></LursoftMap>
        );
      }
    return null;
  }
}
