import React from 'react';
import {FullRow} from '../Components/BootstrapExtensions';
import CardListSearch from '../Components/CardListSearch';
import CardList from '../Components/CardList';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Plus as PlusIcon} from '../Components/Icons';

export default class CardListView extends React.Component {
    state = {
         filterString:""
        ,sortDirection: "asc"
        ,sortColumn: "date"
    }

    static replaceLVLetters(str)
    {
        if (!str) return str;
        return str
                .replace("ē","e")
                .replace("ū","u")
                .replace("ī","i")
                .replace("ā","a")
                .replace("š","s")
                .replace("ģ","g")
                .replace("ķ","k")
                .replace("ļ","l")
                .replace("č","c")
                .replace("ņ","n");
    }

    static filterList(list, filterString) {
        if (!filterString) return list.slice();
        if (!filterString.trim()) return list.slice();

        filterString = filterString.toLowerCase();
        return list.filter(
            card => (card.number+" "+card.description+" "+card.tags.join(" ")).toLowerCase().includes(filterString)
        );
    }

    static sortList(list, sortDirection, sortColumn) {
        let sortedList = null;
        switch(sortColumn) {
            case "name": sortedList = list.sort((a,b) => a.description.localeCompare(b.description)); break;
            case "number": sortedList = list.sort((a,b) => a.number.localeCompare(b.number)); break;
            case "date": sortedList = list.sort((a,b) => {
                if (!a.date && !b.date) return 0;
                if (!a.date) return sortDirection=="asc"?1:-1;
                if (!b.date) return sortDirection=="asc"?-1:1;
                return a.date.getTime() - b.date.getTime();
            }); break;
            default: return list;
        }

        if (sortDirection == "desc")
            sortedList = sortedList.reverse();

        return sortedList;
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.list) return state;
        state.filteredList = CardListView.filterList(props.list, state.filterString);
        state.filteredList = CardListView.sortList(state.filteredList, state.sortDirection, state.sortColumn);
        return state;
    }

    filterAndSort() {
        if (!this.props.list) return;

        this.setState(prevState => {
            prevState.filteredList = CardListView.filterList(this.props.list, prevState.filterString);
            prevState.filteredList = CardListView.sortList(prevState.filteredList, prevState.sortDirection, prevState.sortColumn);
            return prevState;
        });
    }

    onItemClick(item) {
        this.props.onCardSelect(item.number);
    }

    render() {
        return (
            <FullRow id="cardlistview">
                <Col xs={12} className="cardspage-viewheader">
                    <CardListSearch
                        filterString = {this.state.filterString}
                        sortDirection = {this.state.sortDirection}
                        sortColumn = {this.state.sortColumn}
                        onFilterStringChange = {(newFilterStrig) => this.setState({filterString:newFilterStrig},() => this.filterAndSort())}
                        onSortDirectionChange = {(newSortDirection) => this.setState({sortDirection:newSortDirection},() => this.filterAndSort())}
                        onSortColumnChange = {(newSortColumn) => this.setState({sortColumn:newSortColumn},() => this.filterAndSort())}
                    />
                </Col>
                <Col xs={12} className="cardspage-viewcontent" style={{position: "relative"}}>
                    {!this.state.filteredList && <Spinner animation="border" variant="secondary" style={{marginTop:"8px",marginLeft:"calc(50% - 16px)"}}/>}
                    <CardList list={this.state.filteredList} onItemClick={(item) =>  this.onItemClick(item)}/>

                    <Button style={{position:"absolute",bottom:"0.5em",right:"1.5em",borderRadius:"50%"}} onClick={() => this.props.onNewCard()}>
                        <PlusIcon/>
                    </Button>
                </Col>
            </FullRow>
            
        );
    }
}
