import React from 'react';
// React 17: import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; // React 18

import ViewCard from './ViewCard.jsx';
import Application from "./Application.jsx";

import "./Var/Date.js";

import './index.css';

console.log("pathname", window.location.pathname);
const container = document.getElementById('app-root');
const root = createRoot(container);

if (window.location.pathname.startsWith('/viewcard')) {
    // For end users (publishers)
    root.render( < ViewCard / > );
} else {
    // For editing/giving away/returning directories (brothers)
    root.render( < Application / > );
}