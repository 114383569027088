import React from 'react';
import LoginForm from '../Dialogs/LoginForm';

export default class AuthenticationManager extends React.Component {
    state = {firstTime: true};

    showLoginForm() {
        this.setState({shouldAuthenticate: true});
    }

    componentDidMount() {
        fetch("/auth/login", {
            credentials: "same-origin"
        }).then(response => {
            if (response.ok) {
                response.json().then(data => this.setState({userData: data, shouldAuthenticate: false, firstTime:false}));
                return;
            }
            this.showLoginForm();
        }).catch(error => {
            alert(error);
        });
    }

    login(credentials)
    {
        fetch("/auth/login", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(credentials)
        }).then(response => {
            if (response.ok) {
                response.json().then(data => this.setState({userData: data, shouldAuthenticate: false, firstTime:false}));
                return;
            }
            this.showLoginForm();
            alert("Incorrect username/password");
        }).catch(error => {
            alert(error);
        });
    }

    getUserData() {
        //console.log("AUTH_MANAGER: state.userData = ",this.state.userData);
        return this.state.userData;
    }

    render() {
        return (
            <AuthenticationManager.Context.Provider value={this}>
                {!this.state.firstTime && this.props.children}
                <LoginForm show={this.state.shouldAuthenticate} onLogin={(credentials) => this.login(credentials)}/>
            </AuthenticationManager.Context.Provider>
        );
    }
}

AuthenticationManager.Context = React.createContext();