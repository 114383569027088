import CardLocation from './CardLocation';

export default class Card {
    constructor(cardData) {
        this.rawObject = cardData;
        if (!this.rawObject.locations) this.rawObject.locations = [];
        if (!this.rawObject.history) this.rawObject.history = [];

        this.rawObject.history.sort((a, b) => {
            return new Date(b.fromDate) - new Date(a.fromDate);
          });
    }

    get date() {
        if (!this.rawObject.history) return null;
        if (!this.rawObject.history.length) return null;

        let lastEntry = this.rawObject.history[0];//this.rawObject.history.length - 1];

        if (!lastEntry.fromDate && !lastEntry.toDate) return null;
        
        return new Date(lastEntry.toDate || lastEntry.fromDate);
    }

    isOlderThanMonths(months) {
        if (months<=0)
            return true;

        if (!this.rawObject.history) return true;
        if (!this.rawObject.history.length) return true;

        let lastEntry = this.rawObject.history[0];//this.rawObject.history.length - 1];

        if (!lastEntry.fromDate && !lastEntry.toDate) return true;
        if (lastEntry.toDate) {
            let last = new Date(lastEntry.toDate);

            const monthsAgo = new Date();
            monthsAgo.setMonth(monthsAgo.getMonth() - months);
           
            return (+monthsAgo > +last);
        }
        else {
            // last is today and the card isOnHands
            return false; // the months==0 has already been checked; here months>0, and this card is not old
        }
    }

    isOnHands() {
        if (!this.rawObject.history) return false;
        if (!this.rawObject.history.length) return false;

        let lastEntry = this.rawObject.history[0];//this.rawObject.history.length - 1];

        if (!lastEntry.toDate) return true;

        return false;
    }

    isAvailable() {
        if (this.isOnHands()) return false;

        if (this.rawObject.tags && this.rawObject.tags.includes("dirty")) return false;

        return true;
    }

    get tags() {
        let allTags = [];
        if (this.rawObject.tags)
            allTags = this.rawObject.tags.slice();

        if (this.isOnHands())
            allTags.unshift("onhands");
        if (this.isAvailable())
            allTags.unshift("available");

        return allTags.map(elem => "$tag_"+elem);
    }

    update(newParams) {
        //TODO check newParams

        return new Card({...this.rawObject, ...newParams});
    }

    updateLocation(index, newParams) {
        //TODO check index, check newParams

        let locationsBeforeIndex = this.rawObject.locations.slice(0, index);
        let locationsAfterIndex = this.rawObject.locations.slice(index + 1);
        let oldLocation = this.rawObject.locations[index];
        let newLocation = {...oldLocation, ...newParams};
        let newLocationArray = [...locationsBeforeIndex, newLocation, ...locationsAfterIndex];
        return new Card({...this.rawObject, locations: newLocationArray});
    }

    addLocation(newLocation) {
        //TODO check newLocation

        let newLocationArray = [...this.rawObject.locations.slice(0), newLocation];
        return new Card({...this.rawObject, locations: newLocationArray});
    }

    removeLocation(index) {
        //TODO check index

        let locationsBeforeIndex = this.rawObject.locations.slice(0, index);
        let locationsAfterIndex = this.rawObject.locations.slice(index + 1);
        let newLocationArray = [...locationsBeforeIndex, ...locationsAfterIndex];
        return new Card({...this.rawObject, locations: newLocationArray});
    }

    moveLocation(oldIndex, newIndex) {
        //TODO check indices

        if (oldIndex === newIndex) return this;

        if (newIndex < oldIndex) {
            let locationsBeforeNewIndex = this.rawObject.locations.slice(0, newIndex);
            let locationsBetweenIndices = this.rawObject.locations.slice(newIndex, oldIndex);
            let locationsAfterOldIndex = this.rawObject.locations.slice(oldIndex + 1);
            let movedLocation = this.rawObject.locations[oldIndex];
            let newLocationArray = [...locationsBeforeNewIndex, movedLocation, ...locationsBetweenIndices, ...locationsAfterOldIndex];
            return new Card({...this.rawObject, locations: newLocationArray});
        }

        if (oldIndex < newIndex) {
            let locationsBeforeOldIndex = this.rawObject.locations.slice(0, oldIndex);
            let locationsBetweenIndices = this.rawObject.locations.slice(oldIndex + 1, newIndex + 1);
            let locationsAfterNewIndex = this.rawObject.locations.slice(newIndex + 1);
            let movedLocation = this.rawObject.locations[oldIndex];
            let newLocationArray = [...locationsBeforeOldIndex, ...locationsBetweenIndices, movedLocation, ...locationsAfterNewIndex];
            return new Card({...this.rawObject, locations: newLocationArray});
        }
    }

    removeHistory(index) {
        //TODO check index

        let historyBeforeIndex = this.rawObject.history.slice(0, index);
        let historyAfterIndex = this.rawObject.history.slice(index + 1);
        let newhHistoryArray = [...historyBeforeIndex, ...historyAfterIndex];
        return new Card({...this.rawObject, history: newhHistoryArray});
    }

    addHistory(newHistoryEntry) {
        let newhHistoryArray = [...this.rawObject.history.slice(0), newHistoryEntry];
        return new Card({...this.rawObject, history: newhHistoryArray});
    }

    updateHistory(index, newHistoryParams) {
        //TODO check index, check newHistoryParams

        let historyBeforeIndex = this.rawObject.history.slice(0, index);
        let historyAfterIndex = this.rawObject.history.slice(index + 1);
        let oldHistoryEntry = this.rawObject.history[index];
        let newHistoryEntry = {...oldHistoryEntry, ...newHistoryParams};
        let newHistoryArray = [...historyBeforeIndex, newHistoryEntry, ...historyAfterIndex];
        return new Card({...this.rawObject, history: newHistoryArray});
    }

    removeTag(index) {
        if (this.isOnHands() || this.isAvailable()) index = index-1;
        //TODO check index

        let tagsBeforeIndex = this.rawObject.tags.slice(0, index);
        let tagsAfterIndex = this.rawObject.tags.slice(index + 1);
        let newTagsArray = [...tagsBeforeIndex, ...tagsAfterIndex];
        return new Card({...this.rawObject, tags: newTagsArray});
    }

    addTag(newTag) {
        let newTagsArray = [...(this.rawObject.tags || []).slice(0), newTag];
        return new Card({...this.rawObject, tags: newTagsArray});
    }

    prepareForSave() {
        return {...this.rawObject, history: this.rawObject.history.slice(0).reverse()};
    }

    generateToken() {
        let newToken = Math.random().toString(36).substring(2, 15);
        return new Card({...this.rawObject, token: newToken});
    }

    clearToken() {
        let {token, ...newObj} = this.rawObject;
        return new Card(newObj);
    }

    get locations() {return this.rawObject.locations.map(item => new CardLocation(item));}
    //set locations(newLocations) {this.rawObject.locations = newLocations;}

    get description() {return this.rawObject.description;}
    //set description(newDescription) {this.rawObject.description = newDescription;}

    get number() {return this.rawObject.number;}
    //set number(newNumber) {this.rawObject.number = newNumber;}

    get history() {return this.rawObject.history;}
    //set history(newHistory) {this.rawObject.history = newHistory;}

    get map() {return this.rawObject.map;}
    //set map(newMap) {this.rawObject.map = newMap;}

    get token() {return this.rawObject.token;}
    //set token(newToken) {this.rawObject.token = newToken;}
}

//Card = Card;