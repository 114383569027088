import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import React, { createRef } from "react";

import TranslationManager from "./Managers/Translation";
import Card from "./Entities/Card";
import CardView from "./Views/Card";
import MapView from "./Views/Map";
import $ from "jquery";


export default class ViewCard extends React.Component {
  divRef;

  constructor(props) {
    super(props);
    this.state = {};
    this.divRef = createRef();
  }

  updateHeight() {
    if (this.divRef.current) {
      
      let h = $(this.divRef.current).height()
      console.log("HEI", h);

      $(document.body).height(h);
      $("#app-root").height(h);
    }
  }

  componentDidMount() {
    let args = new URLSearchParams(location.search);
    
    if (args.has("token")) {
      let token = args.get("token");
      fetch("cards/token/" + encodeURIComponent(token), {
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((newCard) => this.setState({ card: new Card(newCard[0]) }, ()=>this.updateHeight()));
    }
    
  }

  getDeadlineText(card, context) {
    console.log(this.state);
    if (!card.fromDate) return "";
    let last = this.state.card.fromDate;
    last = Date.parse(last);

    let date3 = new Date();
    date3.setTime(last);
    date3 = new Date(date3.setMonth(date3.getMonth() + 3));
    let date4 = new Date();
    date4.setTime(last);
    date4 = new Date(date4.setMonth(date4.getMonth() + 4));

    let now = Date.now();

    let diff = now - last;
    let days = Math.round(diff / (24 * 3600 * 1000));

    var s = context
      .getTranslation("$territory_on_hands")
      .split("{0}")
      .join(days);

    if (now > date4.getTime()) {
      return (
        <div>
          {s}
          <br />
          <font color="red">
            {context.getTranslation("$territory_on_hands_warning2")}
          </font>
        </div>
      );
    } else if (now > date3.getTime())
      return (
        <div>
          {s}
          <br />
          <font color="orange">
            {context.getTranslation("$territory_on_hands_warning1")}
          </font>
        </div>
      );

    return s;
  }

  onChange(newCard) {
    this.setState({ card: newCard }, () => {
      this.updateHeight();
    });
  }

  render() {
    if (!this.state.card) return null; // card not fetched
    console.log("CARD", this.state.card, window.innerHeight);

    return (
      <TranslationManager>
        <TranslationManager.Context.Consumer>
          {(context) => (
            <React.Fragment>
              <div ref={this.divRef}>
              <CardView
                card={this.state.card}
                readOnly={true}
                endUser={true}
                onChange={(newObj) =>
                  this.onChange(this.state.card.update(newObj))
                }
              />
              <div>{this.getDeadlineText(this.state.card, context)}</div>
              <div style={{height:window.innerHeight+"px"}}>
              <MapView
                card={this.state.card}
                endUser={true}
                onChange={(newObj) =>
                  this.onChange(this.state.card.update(newObj))
                }
              />
              </div>
              </div>
            </React.Fragment>
          )}
        </TranslationManager.Context.Consumer>
      </TranslationManager>
    );
  }
}
